<template>
  <v-container
    fluid
    style="background-color: #E6E6E6;"
  >
    <v-row class="py-2 mb-n2 ml-4">
      <v-col class="text-left">
        <a
          href="#"
          style="text-decoration:none;"
          @click="cancelButtonClick"
        >
          <div class="d-inline-flex">
            <v-icon color="purple">
              mdi-chevron-left
            </v-icon>
            <p
              class="text-h4 my-3 mr-10"
              style="color: purple;"
            >
              Kembali
            </p>
          </div>
        </a>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="px-5 py-5 mt-n4 mb-0 mx-9">
          <v-row class="mt-n2">
            <v-col>
              <span class="custom-title">
                Informasi Umum
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <span class="custom-subtitle">
                Perusahaan
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <span class="custom-subtitle">
                Nama Aset
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <span class="custom-subtitle">
                Tanggal Pemesanan
              </span>
            </v-col>
          </v-row>

          <v-row class="mt-n4">
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <span class="custom-content">
                {{ dataProductionDetails.prt_comp_name_legal }}
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <span class="custom-content">
                {{ dataProductionDetails.ass_name }}
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <span class="custom-content">
                {{ dataProductionDetails.created_time }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <span class="custom-subtitle">
                No Produksi
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <span class="custom-subtitle">
                No Order
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            />
          </v-row>

          <v-row class="mt-n4">
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <span class="custom-content">
                {{ dataProductionDetails.code }}
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <span class="custom-content">
                {{ dataProductionDetails.order_code }}
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            />
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col>
        <v-card class="px-5 py-5 mt-n4 mb-0 mx-9">
          <v-row class="mt-n2">
            <v-col>
              <span class="custom-title">
                Dokumen Media
              </span>
            </v-col>
          </v-row>

          <template v-if="dataProductionModules.section_document.list_documents.length > 0">
            <v-row
              v-for="(doc, i) in dataProductionModules.section_document.list_documents"
              :key="i"
              class="mb-n2"
            >
              <v-col
                cols="12"
                sm="6"
                md="4"
                style="padding-top: 15px;"
              >
                <span class="custom-subtitle">
                  {{ doc.name }}
                </span>

                <template v-if="doc.file_size_raw !== null">
                  <span class="custom-content">
                    ({{ doc.file_size }})
                  </span>
                </template>
                <template v-else>
                  <span class="custom-content">
                    ( - )
                  </span>
                </template>

                <v-icon
                  v-if="doc.file_path_view !== null"
                  @click="showModalImage(doc.file_path_view, doc.type, doc.file_type)"
                >
                  mdi-eye
                </v-icon>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <!-- Status 3 = `sedang proses verifikasi` -->
                <template v-if="doc.status === 3">
                  <template v-if="isAllowReview">
                    <div>
                      <v-btn
                        color="primary"
                        small
                        @click="verifyButtonClick(1, doc.hash)"
                      >
                        <span
                          style="font-size: 12px;"
                          class="text-capitalize"
                        >
                          Telah Sesuai
                        </span>
                      </v-btn>

                      <v-btn
                        color="red"
                        small
                        @click="verifyButtonClick(2, doc.hash)"
                      >
                        <span
                          style="font-size: 12px;"
                          class="text-capitalize"
                        >
                          Belum Sesuai
                        </span>
                      </v-btn>
                      <span
                        style="color: red; font-size: 14px; padding-top: 2px;"
                      >
                        {{ errMssg[i] }}
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    <span>
                      Tidak ada otorisasi
                    </span>
                  </template>
                </template>

                <template v-if="doc.file_size_raw === null">
                  <span class="custom-subtitle">
                    Mitra Belum Upload
                  </span>
                </template>

                <template v-if="doc.status === 1">
                  <span class="custom-subtitle">
                    Dokumen Telah Terverifikasi
                  </span>
                </template>

                <!-- <v-spacer
                  v-else
                  class="mx-o my-0 px-0 py-4"
                /> -->
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              />
            </v-row>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col>
        <v-card class="px-5 pt-5 pb-2 mt-n4 mb-0 mx-9">
          <v-row class="mt-n2">
            <v-col class="px-5 pt-4 pb-1 mt-0 mb-0">
              <span class="custom-title">
                Desain Iklan
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-2 pb-1 mt-0 mb-0">
              <template v-if="dataProductionModules.section_artwork.file_arr.length > 0">
                <!-- <tiny-slider
                  ref="tinySlider"
                  v-bind="tinySliderOptions"
                >
                  <div
                    v-for="(photo, i) in dataProductionModules.section_artwork.file_arr"
                    :key="i"
                  >
                    <v-img
                      style="cursor: pointer; hover"
                      :src="photo.path"
                      max-width="95%"
                      height="200"
                      lazy-src="@/assets/image-loader.png"
                      @click="zoomMediaImage(photo.path)"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="purple"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </tiny-slider> -->
                <!-- <template v-if="dataProductionModules.section_artwork.file_arr.length > 3">
                  <div class="d-flex justify-space-between arrow">
                    <v-icon
                      x-large
                      style="color: #F2F2F2;"
                      @click="prev"
                    >
                      mdi-chevron-left
                    </v-icon>
                    <v-icon
                      x-large
                      style="color: #F2F2F2;"
                      @click="next"
                    >
                      mdi-chevron-right
                    </v-icon>
                  </div>

                  <div class="d-flex justify-center py-3">
                    <span
                      v-for="(n, i) in listImages"
                      :id="'dot-' + i"
                      :key="n"
                      class="dot"
                      @click="navigationClick(i)"
                    />
                  </div>
                </template> -->

                <!-- Request Features on 05 April 2021
                https://www.notion.so/travikr/CMS-Production-1554b9f2a58c4fe385a54a2d6f5fc566 -->
                <div
                  v-for="(photo, i) in dataProductionModules.section_artwork.file_arr"
                  :key="i"
                  class="mb-1"
                >
                  <span
                    style="
                    cursor: pointer;
                    margin-left: 10px;
                    color: blue;
                  "
                    @click="downloadFile(photo.path)"
                  >
                    {{ photo.name }} {{ '(' + photo.size_format + ')' }}
                  </span>
                </div>
              </template>
            </v-col>
          </v-row>

          <v-divider class="mx-2 mt-4 pt-1 pb-0" />

          <v-row>
            <v-col>
              <span class="pl-2 custom-subtitle">
                {{ dataProductionModules.section_artwork.status_string }} - {{ dataProductionModules.section_artwork.file_notes }}
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="px-5 pt-5 pb-2 mt-0 mb-0 mx-9">
          <v-row class="mt-n2 pl-2">
            <v-col>
              <span class="custom-title">
                Percetakan Media
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="px-5 py-2 mt-0 mb-0 ml-0"
            >
              <span class="custom-subtitle">
                Vendor
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
              class="px-5 py-2 mt-0 mb-0"
            >
              <span class="custom-subtitle">
                No.Hp
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            />
          </v-row>

          <v-row class="mt-n2">
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="px-5 py-2 mt-0 mb-0 ml-0"
            >
              <span class="custom-content">
                {{ dataProductionModules.section_printer.vendor_name }}
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
              class="px-5 py-2 mt-0 mb-0"
            >
              <span class="custom-content">
                {{ dataProductionModules.section_printer.vendor_phone }}
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            />
          </v-row>

          <v-divider class="mx-2 mt-2 py-0" />

          <v-row>
            <v-col>
              <span
                v-if="dataProductionModules.section_printer.vendor_name !== null"
                class="pl-2 custom-subtitle"
              >
                {{ dataProductionModules.section_printer.done_print_string }}
              </span>
              <span
                v-else
                class="pl-2 custom-subtitle"
              >
                Belum ada status
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="px-5 pt-5 pb-2 mt-0 mb-0 mx-9">
          <v-row class="mt-n2 pl-2">
            <v-col>
              <span class="custom-title">
                Pemasangan Media
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="px-5 py-2 mt-0 mb-0 ml-0">
              <span class="custom-subtitle">
                Vendor
              </span>
            </v-col>

            <v-col class="px-5 py-2 mt-0 mb-0">
              <span class="custom-subtitle">
                No.Hp
              </span>
            </v-col>

            <v-col class="px-5 py-2 mt-0 mb-0">
              <span class="custom-subtitle">
                Tanggal Pemasangan
              </span>
            </v-col>
          </v-row>

          <v-row class="mt-n2">
            <v-col>
              <span class="pl-2 custom-content">
                {{ dataProductionModules.section_printer.vendor_name }}
              </span>
            </v-col>

            <v-col
              class="px-5 py-2 mt-0 mb-0"
            >
              <span class="pl-2 custom-content">
                {{ dataProductionModules.section_printer.vendor_phone }}
              </span>
            </v-col>

            <v-col
              class="px-5 py-2 mt-0 mb-0"
            >
              <template v-if="dataProductionModules.section_installer.installed_photo.length > 0">
                <span class="pl-2 custom-content">
                  {{ dataProductionModules.section_installer.installed_photo[0].tanggal || '-' }}
                </span>
              </template>
              <template v-else>
                <span
                  class="pl-2 custom-content"
                  v-text="'-'"
                />
              </template>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-2 pb-1 mt-0 mb-0">
              <template v-if="linkInstalledImages.length > 0">
                <tiny-slider
                  ref="tinySlider"
                  v-bind="tinySliderOptions"
                >
                  <div
                    v-for="(v, i) in linkInstalledImages"
                    :id="'photo-' + i"
                    :key="i"
                  >
                    <v-img
                      style="cursor: pointer; hover"
                      :src="v"
                      max-width="95%"
                      height="200"
                      lazy-src="@/assets/image-loader.png"
                      @click="zoomMediaImage(v)"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="purple"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </tiny-slider>
                <template v-if="dataProductionModules.section_installer.installed_photo.length > 3">
                  <div class="d-flex justify-space-between arrow">
                    <v-icon
                      x-large
                      style="color: #F2F2F2;"
                      @click="prev"
                    >
                      mdi-chevron-left
                    </v-icon>
                    <v-icon
                      x-large
                      style="color: #F2F2F2;"
                      @click="next"
                    >
                      mdi-chevron-right
                    </v-icon>
                  </div>

                  <div class="d-flex justify-center py-3">
                    <span
                      v-for="(n, i) in listImages"
                      :id="'dot-' + i"
                      :key="n"
                      class="dot"
                      @click="navigationClick(i)"
                    />
                  </div>
                </template>
              </template>
            </v-col>
          </v-row>

          <v-divider class="mx-2 mt-2 py-0" />

          <v-row>
            <v-col>
              <span class="pl-2 custom-subtitle">
                {{ dataProductionModules.section_done.title || '-' }}
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="px-5 pt-5 pb-2 mt-0 mb-0 mx-9">
          <v-row class="mt-n2 pl-2">
            <v-col>
              <span class="custom-title">
                Riwayat Log
              </span>
            </v-col>
          </v-row>

          <v-row class="mt-n6">
            <v-col>
              <time-line
                :data-timeline="dataProductionLogs"
                module="production"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogViewDocument"
      width="600"
    >
      <v-card class="px-5 pt-5 pb-10">
        <v-row>
          <v-col>
            <span
              class="ml-5 font-weight-bold"
              style="font-size: 16px;"
            >
              {{ dialogTitle }}
            </span>
          </v-col>
          <v-col class="text-right">
            <v-btn
              icon
              @click="dialogViewDocument = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          class="mt-n6"
        >
          <v-col>
            <span
              class="ml-5"
              style="font-size: 14px;"
            >Untuk melihat file pastikan IDM kamu dinonaktifkan terlebih dahulu</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="d-flex justify-center align-center">
              <v-img
                v-if="docFileType === 'image'"
                :src="dialogURL"
                max-width="500"
                lazy-src="@/assets/image-loader.png"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="purple"
                    />
                  </v-row>
                </template>
              </v-img>
              <iframe
                v-if="docFileType === 'pdf'"
                :src="dialogURL"
                width="100%"
                height="500"
              />
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-dialog
        v-model="confirmDialog"
        max-width="360"
      >
        <v-card>
          <v-card-text class="pb-2 text-center">
            <span style="font-size: 14px;">
              {{ confirmDialogText }}
            </span>
          </v-card-text>
          <v-card-text v-if="isEnableReason">
            <v-textarea
              ref="approvalReasonTextArea"
              v-model="approvalReason"
              class=" mt-n4 mb-n8 py-0 px-1"
              placeholder="Masukkan alasan penolakan"
              outlined
              color="purple"
              no-resize
              background-color="#FAFAFA"
              :error-messages="approvalReasonErrorMessages"
            />
          </v-card-text>
          <v-card-actions class="px-7 pt-1 pb-3">
            <v-row>
              <v-col>
                <v-btn
                  color="white"
                  class="text-capitalize"
                  small
                  block
                  @click="confirmButtonClick()"
                >
                  <span style="font-size: 14px;">
                    Ya
                  </span>
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  color="purple"
                  class="text-capitalize"
                  small
                  block
                  @click="confirmDialog = false"
                >
                  <span style="font-size: 14px;">
                    Tidak
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Zoom Image -->
    <div
      v-if="zoomImage"
      class="modal-image"
    >
      <span
        class="modal-image-close"
        @click="zoomImage = false"
      >&times;</span>
      <img
        class="modal-image-content"
        :src="srcZoomImage"
      >
    </div>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      TimeLine: () => import('../../component/TimeLine'),
    },

    data: () => ({
      dialogViewDocument: false,
      dialogTitle: 'Loading . . .',
      dialogURL: '@/assets/image-loader.png',
      docFileType: 'image',
      confirmDialog: false,
      confirmDialogText: '',
      dataProductionDetails: [],

      dataProductionModules: {
        section_document: {
          title: '',
          list_documents: [],
        },
        section_artwork: {
          title: '',
          file_arr: [],
          status_string: '',
        },
        section_printer: {
          title: '',
          status_string: '',
        },
        section_installer: {
          title: '',
          status_string: '',
          installed_photo: [],
        },
        section_done: {
          status_string: '',
        },
      },
      dataProductionLogs: [],

      // section document:
      docValue: [null, null],
      errMssg: { 0: '', 1: '' },
      tmpDocStatus: undefined,
      tmpDocHash: undefined,
      approvalReason: '',
      approvalReasonErrorMessages: '',
      isEnableReason: false,
      isAllowReview: false,

      // section artwork:
      tinySliderOptions: {
        mouseDrag: true,
        loop: false,
        items: 4,
        controls: false,
      },
      zoomImage: false,
      srcZoomImage: '@/assets/image-loader.png',

      // section installer:
      linkInstalledImages: [],
    }),

    computed: {
      listImages () {
        let imageCount = 0
        if (this.dataProductionModules.section_artwork.file_arr.length > 0) {
          imageCount = this.dataProductionModules.section_artwork.file_arr.length
        }
        if (imageCount > 0) {
          imageCount = Math.ceil(imageCount / 4)
        }
        return imageCount
      },
    },

    watch: {
    },

    created () {
      this.initialize(this.$route.params.id)
    },

    methods: {
      async initialize (id) {
        // Check user menus
        const userMenus = localStorage.getItem('userMenus').split(',')
        if (userMenus.includes('PRODUKSI_REVIEW')) {
          this.isAllowReview = true
        }

        // define vm as this
        const vm = this

        const requestBody = {
          prod_hash: id,
        }

        await axios.post('/t/production/detail', this.$qs.stringify(requestBody)).then((res) => {
          if (res.data.status === 200) {
            vm.dataProductionDetails = res.data.data.details
            vm.dataProductionModules = res.data.data.modules
            vm.dataProductionLogs = res.data.data.logs
            this.getLinkImages(res.data.data.modules.section_installer.installed_photo)
          }
        }).catch((e) => {
          // console.log(e)
        })

        // console.log(this.dataProductionModules.section_document.list_documents)
      },

      // verifyButtonClick (item) {
      //   const requestBody = {}
      //   // check tax or retribution
      //   if (item === 'TAX') {
      //     requestBody.prod_hash = this.dataProductionDetails.hash
      //     requestBody.prod_doc_hash = this.dataProductionModules.section_document.list_documents[0].hash
      //     requestBody.new_stat = 1
      //   } else {
      //     requestBody.prod_hash = this.dataProductionDetails.hash
      //     requestBody.prod_doc_hash = this.dataProductionModules.section_document.list_documents[1].hash
      //     requestBody.new_stat = 1
      //   }
      //   // call api
      //   axios.post('/t/production/review/modify', requestBody).then((res) => {
      //     if (res.data.status === 200) {
      //       this.$toast.success(res.data.message)
      //       this.initialize(this.$route.params.id)
      //     }
      //   }).catch((e) => {
      //     e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
      //   })
      // },

      getLinkImages (data) {
        if (data.length === 0) return
        data.map((v) => {
          if (v.photos.length > 0) {
            v.photos.map((x) => {
              this.linkInstalledImages.push(x.link)
            })
          }
        })
      },

      verifyButtonClick (key, hash) {
        this.tmpDocStatus = key
        this.tmpDocHash = hash

        key === 1 ? this.isEnableReason = false : this.isEnableReason = true

        if (key === 1) {
          this.confirmDialogText = 'Apakah anda yakin menyetujui dokumen ini ?'
        } else {
          this.confirmDialogText = 'Apakah anda yakin menolak dokumen ini ?'
        }

        this.confirmDialog = true
      },

      rejectButtonClick (item) {
        const requestBody = {}
        if (item === 'TAX') {
          requestBody.prod_hash = this.dataProductionDetails.hash
          requestBody.prod_doc_hash = this.dataProductionModules.section_document.list_documents[0].hash
          requestBody.new_stat = 2
        } else {
          requestBody.prod_hash = this.dataProductionDetails.hash
          requestBody.prod_doc_hash = this.dataProductionModules.section_document.list_documents[1].hash
          requestBody.new_stat = 2
        }
        axios.post('/t/production/review/modify', this.$qs.stringify(requestBody)).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            this.initialize(this.$route.params.id)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      cancelButtonClick () {
        this.$router.push({ name: 'Production' })
      },

      showModalImage (link, title, fileType) {
        this.dialogURL = link
        this.dialogTitle = title
        this.docFileType = fileType
        this.dialogViewDocument = true
      },

      async confirmButtonClick () {
        const approvalReasonValue = this.approvalReason.trim()
        if (!approvalReasonValue && this.tmpDocStatus === 2) {
          this.approvalReasonErrorMessages = 'Approval Reason harus diisi.'
          this.$refs.approvalReasonTextArea.focus()
          return
        }

        // this.dataAsset.documents.map((val, i) => {
        //   if (val.status === 1) this.docValue[i] = 1
        //   if (this.docValue[i] === null) {
        //     this.errMssg[i] = 'Status dokumen media tidak boleh kosong'
        //   } else {
        //     this.errMssg[i] = ''
        //   }
        //   this.documentParameter[val.type] = this.docValue[i]
        // })

        // for (const key in this.errMssg) {
        //   if (this.errMssg[key] !== '') return
        // }

        const requestBody = {
          prod_hash: this.$route.params.id,
          prod_doc_hash: this.tmpDocHash,
          new_stat: this.tmpDocStatus,
        }

        console.log(requestBody)

        await axios.post('/t/production/review/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            this.initialize(this.$route.params.id)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })

        this.confirmDialog = false
      },

      prev () {
        this.$refs.tinySlider.slider.goTo('prev')
      },

      next () {
        this.$refs.tinySlider.slider.goTo('next')
      },

      navigationClick (i) {
        this.$refs.tinySlider.slider.goTo(i)
      },

      zoomMediaImage (link) {
        this.srcZoomImage = link
        this.zoomImage = true
      },

      downloadFile (link) {
        window.open(link, '_blank')
      },
    },
  }
</script>

<style scoped>
  .custom-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
  }

  .custom-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #808080;
  }

  .custom-content {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #808080;
  }

  /* The Modal (background) */
  .modal-image {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  }

  /* Modal Content (image) */
  .modal-image-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }

  /* Add Animation */
  .modal-image-content {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  @-webkit-keyframes zoom {
    from {-webkit-transform:scale(0)}
    to {-webkit-transform:scale(1)}
  }

  @keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
  }

  /* The Close Button */
  .modal-image-close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
  }

  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px){
    .modal-image-content {
      width: 100%;
    }
  }

  /* Next & previous buttons */
  .arrow {
    cursor: pointer;
    position: absolute;
    top: 45%;
    color: white;
    width: 96%;
  }
</style>
